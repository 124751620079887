<template>
  <el-dialog
    :title="$t('rate.createRate')"
    v-model="isShow"
    width="700px"
    :before-close="handleClose"
  >
    <el-form
      ref="rateForm"
      :inline="true"
      :model="rateInfo"
      size="mini"
      :rules="rateRules"
    >
      <div class="row">
        <el-form-item
          :label="$t('rate.originalCurrency')"
          prop="originalCurrency"
          label-width="120px"
        >
          <el-select
            filterable
            v-model="rateInfo.originalCurrency"
            :placeholder="$t('rate.originalCurrency')"
            clearable
            size="mini"
          >
            <el-option
              :label="item"
              :value="item"
              v-for="(item, index) in currencyList"
              :key="index"
            />
          </el-select>
          <!-- <el-input
            v-model.trim="rateInfo.originalCurrency"
            :placeholder="$t('system.pleaseInput') + $t('rate.originalCurrency')"
            size="mini"
          ></el-input> -->
        </el-form-item>
        <el-form-item
          :label="$t('rate.targetCurrency')"
          prop="targetCurrency"
          label-width="120px"
        >
          <el-select
            filterable
            v-model="rateInfo.targetCurrency"
            :placeholder="$t('rate.targetCurrency')"
            clearable
            size="mini"
          >
            <el-option
              :label="item"
              :value="item"
              v-for="(item, index) in currencyList"
              :key="index"
            />
          </el-select>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('rate.effectiveDate')"
          prop="effectiveDate"
          label-width="120px"
        >
          <el-date-picker
            v-model="rateInfo.effectiveDate"
            type="date"
            :placeholder="$t('system.pleaseSelect') + $t('rate.effectiveDate')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          :label="$t('rate.expiryDate')"
          prop="expiryDate"
          label-width="120px"
        >
          <el-date-picker
            v-model="rateInfo.expiryDate"
            type="date"
            :placeholder="$t('system.pleaseSelect') + $t('rate.expiryDate')"
          >
          </el-date-picker>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('rate.exchangeRate')"
          prop="exchangeRate"
          label-width="120px"
        >
          <el-input
            v-model.trim="rateInfo.exchangeRate"
            :placeholder="$t('system.pleaseInput') + $t('rate.exchangeRate')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('rate.organization')"
          prop="orgId"
          label-width="120px"
        >
          <el-select
            filterable
            v-model="rateInfo.orgId"
            :placeholder="$t('system.pleaseSelect') + $t('rate.organization')"
            clearable
          >
            <el-option
              :label="item.companyNameCn"
              :value="'' + item.id"
              v-for="(item, index) in organizationList"
              :key="index"
            >
              <span style="float: left">{{ item.companyNameCn }}</span>
              <span
                style="
                  float: right;
                  color: 'var(--el-text-color-secondary)';
                  font-size: 12px;
                "
                >{{ item.companyCode }}</span
              ></el-option
            >
          </el-select>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('rate.remark')"
          prop="remark"
          label-width="120px"
        >
          <el-input
            v-model.trim="rateInfo.remark"
            :placeholder="$t('system.pleaseInput') + $t('rate.remark')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose" size="mini">{{
          $t("system.cancel")
        }}</el-button>
        <el-button type="primary" @click="doCreateRate" size="mini">{{
          $t("system.confirm")
        }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import baseServer from "../source/basedata";
export default {
  props: ["isShowDialog"],
  emits: ["onDialogClose"],
  data() {
    return {
      rateInfo: {
        originalCurrency: null,
        targetCurrency: null,
        effectiveDate: null,
        expiryDate: null,
        exchangeRate: null,
        orgId: null,
        remark: null,
      },
      rateRules: {
        targetCurrency: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") + this.$t("rate.targetCurrency"),
            trigger: "blur",
          },
        ],
        originalCurrency: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") + this.$t("rate.originalCurrency"),
            trigger: "blur",
          },
        ],
        effectiveDate: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") + this.$t("rate.effectiveDate"),
            trigger: "blur",
          },
        ],
        expiryDate: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") + this.$t("rate.expiryDate"),
            trigger: "blur",
          },
        ],
        orgId: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") + this.$t("rate.organization"),
            trigger: "blur",
          },
        ],
        exchangeRate: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") + this.$t("rate.exchangeRate"),
            trigger: "blur",
          },
        ],
      },
      // organizationList: [
      //   { id: 1, name: "壹站供应链管理有限公司" },
      //   { id: 2, name: "上海道骊物流有限公司" }
      // ]
    };
  },
  computed: {
    organizationList() {
      return this.$store.state.constants.saleCompanys;
    },
    currencyList() {
      return this.$store.state.constants.currencyList;
    },
    isShow() {
      return this.isShowDialog;
    },
  },
  methods: {
    handleClose() {
      this.$emit("onDialogClose");
    },
    doCreateRate() {
      this.$refs["rateForm"].validate((valid) => {
        if (valid) {
          baseServer.addRate(this.rateInfo).then(() => {
            this.$confirm(
              this.$t("rate.createSuccess"),
              this.$t("system.continue") + "?",
              {
                confirmButtonText: this.$t("system.confirm"),
                cancelButtonText: this.$t("system.cancel"),
                type: "success",
              }
            )
              .then(() => {
                this.$refs["rateForm"].resetFields();
              })
              .catch(() => {
                this.handleClose();
              });
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :deep(.el-form-item__label) {
    font-size: 12px;
  }
  :deep(.el-input--mini) {
    max-width: 180px;
  }
}
</style>
