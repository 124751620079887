import request from "@/api/request";

const baseServer = {

    /** 港口 */
    queryHarbour: params =>
        request({
            url: "/baseHub/queryHub",
            method: "get",
            params: params,
        }),
    addHarbour: params =>
        request.post('/baseHub/addHub', params),
    updateHarbour: params =>
        request.post('/baseHub/updateHub', params),

    /** 费率 */
    queryRate: params =>
        request({
            url: "/baseCurrencyRate/queryCurrencyRate",
            method: "get",
            params: params,
        }),
    addRate: params =>
        request.post('/baseCurrencyRate/addCurrencyRate', params),
    updateRate: params =>
        request.post('/baseCurrencyRate/updateCurrencyRate', params),

    /** 费用组 */
    queryChargeGroup: params =>
        request({
            url: "/charge/queryChargeGroup",
            method: "get",
            params: params,
        }),
    addChargeGroup: params =>
        request.post('/charge/addChargeGroup', params),
    updateChargeGroup: params =>
        request.post('/charge/updateChargeGroup', params),

    /** 费用 */
    queryCharge: params =>
        request({
            url: "/charge/queryCharge",
            method: "get",
            params: params,
        }),
    addCharge: params =>
        request.post('/charge/addCharge', params),
    updateCharge: params =>
        request.post('/charge/updateCharge', params),
}




export default baseServer