<template>
  <div class="container">
    <el-container>
      <el-header>
        <el-row type="flex" :gutter="10" style="width: 100vw">
          <el-col :span="3">
            <el-select
              filterable
              v-model="filterOption.originalCurrency"
              :placeholder="$t('rate.originalCurrency')"
              clearable
              size="mini"
            >
              <el-option
                :label="item"
                :value="item"
                v-for="(item, index) in currencyList"
                :key="index"
              />
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-select
              filterable
              v-model="filterOption.targetCurrency"
              :placeholder="$t('rate.originalCurrency')"
              clearable
              size="mini"
            >
              <el-option
                :label="item"
                :value="item"
                v-for="(item, index) in currencyList"
                :key="index"
              />
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-select
              filterable
              v-model="filterOption.orgId"
              :placeholder="$t('rate.organization')"
              clearable
              size="mini"
            >
              <el-option
                :label="item.companyNameCn"
                :value="'' + item.id"
                v-for="(item, index) in organizationList"
                :key="index"
              >
                <span style="float: left">{{ item.companyNameCn }}</span>
                <span
                  style="
                    float: right;
                    color: 'var(--el-text-color-secondary)';
                    font-size: 12px;
                  "
                  >{{ item.companyCode }}</span
                ></el-option
              >
            </el-select>
          </el-col>
          <el-col :span="4" :offset="11">
            <div style="float: right">
              <el-button type="primary" size="mini" @click="doSearch">{{
                $t("system.search")
              }}</el-button>
              <el-button type="warning" size="mini" @click="doReset">{{
                $t("system.reset")
              }}</el-button>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <div class="tableHeader">
          <!-- <el-button size="mini">{{ $t("system.import") }}</el-button> -->
          <el-button size="mini" @click="showCreateDialog">{{
            $t("system.create")
          }}</el-button>
          <!-- <el-button size="mini">{{ $t("system.delete") }}</el-button> -->
        </div>
        <el-table
          :data="tableData"
          ref="accountTable"
          border
          :height="tHeight"
          v-loading="isTableLoading"
          @selection-change="onTableSelectionChange"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column type="selection" width="40" fixed></el-table-column>
          <el-table-column
            type="index"
            :label="$t('system.index')"
            width="60"
            align="center"
            fixed
          ></el-table-column>
          <el-table-column
            prop="originalCurrency"
            :label="$t('rate.originalCurrency')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="targetCurrency"
            :label="$t('rate.targetCurrency')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="exchangeRate"
            :label="$t('rate.exchangeRate')"
            align="center"
            width="120"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="orgId"
            :label="$t('rate.organization')"
            align="center"
            width="220"
            show-overflow-tooltip
            ><template #default="scope">
              {{ getOrgName(scope.row.orgId) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="effectiveDate"
            :label="$t('rate.effectiveDate')"
            align="center"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="expiryDate"
            :label="$t('rate.expiryDate')"
            align="center"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="remark"
            :label="$t('rate.remark')"
            align="center"
            width="320"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('system.operation')"
            fixed="right"
            align="center"
            width="150"
          >
            <template v-slot="scope">
              <el-button
                type="text"
                size="mini"
                icon="el-icon-edit"
                @click="edit(scope.row)"
                >{{ $t("system.edit") }}</el-button
              >
              <!-- <el-button
                type="text"
                style="color:#E6A23C;"
                size="mini"
                icon="el-icon-delete"
                @click="del(scope.row)"
              >{{ $t("system.delete") }}</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div style="float: right; margin-top: 10px">
          <el-pagination
            @size-change="onTableSizeChange"
            @current-change="onTablePageChange"
            :current-page="filterOption.pageNo"
            :page-sizes="[10, 50, 100, 200]"
            layout="total,sizes,prev, pager, next"
            :total="filterOption.total"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>

    <create-rate-dialog
      v-if="isShowCreateDialog"
      :isShowDialog="isShowCreateDialog"
      @onDialogClose="closeCreateDialog"
    />

    <update-rate-dialog
      v-if="isShowUpdateDialog"
      :isShowDialog="isShowUpdateDialog"
      :rowInfo="currentRow"
      @onDialogClose="closeUpdateDialog"
    />
  </div>
</template>

<script>
import baseServer from "./source/basedata";
import mixin from "@/utils/mixin.js";
import CreateRateDialog from "./dialogs/createRate";
import UpdateRateDialog from "./dialogs/updateRate";
import moment from "moment";
export default {
  name: "rate",
  components: {
    CreateRateDialog,
    UpdateRateDialog,
  },
  mixins: [mixin],
  data() {
    return {
      isTableLoading: false,
      isShowCreateDialog: false,
      isShowUpdateDialog: false,
      filterOption: {
        originalCurrency: null,
        targetCurrency: null,
        orgId: null,

        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      // organizationList: [
      //   { id: 1, name: "壹站供应链管理有限公司" },
      //   { id: 2, name: "上海道骊物流有限公司" }
      // ],
      tableData: [],
      tableSelection: [],
      currentRow: null,
    };
  },
  computed: {
    organizationList() {
      return this.$store.state.constants.saleCompanys;
    },
    currencyList() {
      return this.$store.state.constants.currencyList;
    },
    tHeight() {
      return this.tableHeight - 135;
    },
  },
  mounted() {},
  methods: {
    getOrgName(orgId) {
      let orgInfo = this.organizationList.find((item) => orgId == "" + item.id);
      return orgInfo ? orgInfo.companyNameCn : "-";
    },
    doSearch() {
      baseServer
        .queryRate({
          ...this.filterOption,
          current: this.filterOption.pageNo,
          size: this.filterOption.pageSize,
        })
        .then((res) => {
          this.tableData = res.data ? res.data.records : [];
          this.tableData.forEach((item) => {
            item.effectiveDate = moment(item.effectiveDate).format(
              "YYYY-MM-DD"
            );
            item.expiryDate = moment(item.expiryDate).format("YYYY-MM-DD");
          });
          this.filterOption.total = res.data.total;
        });
    },
    edit(row) {
      this.currentRow = row;
      this.isShowUpdateDialog = true;
    },
    del(row) {
      console.log(row);
    },
    onTableSelectionChange(val) {
      this.tableSelection = val;
    },
    onTableSizeChange(size) {
      this.filterOption = { ...this.filterOption, pageSize: size, pageNo: 1 };
      this.doSearch();
    },
    onTablePageChange(page) {
      this.filterOption = { ...this.filterOption, pageNo: page };
      this.doSearch();
    },
    showCreateDialog() {
      this.isShowCreateDialog = true;
    },
    closeCreateDialog() {
      this.isShowCreateDialog = false;
    },
    closeUpdateDialog() {
      this.isShowUpdateDialog = false;
    },
    doReset() {
      this.filterOption = {
        originalCurrency: null,
        targetCurrency: null,
        orgId: null,

        pageNo: 1,
        pageSize: 10,
        total: 0,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: calc(100% - 20px);
  background-color: white;
  overflow-y: scroll;
  padding: 10px;

  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
    :deep(el-form-item) {
      margin-right: 10px;
    }
  }

  :deep(.el-main) {
    padding: 0 20px;
    .tableHeader {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 48px;
    }
  }
}
</style>
